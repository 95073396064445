
import {strings as constants} from './constants.js';

'use strict';

export class NavDrawerItems {
  static get defaultItems() {
    return [
      /*
        {type: 'link', href: '/roadshow/', label: 'Virtual Roadshow',
            icon: 'home_repair_service'},
        {divider: true},
        {type: 'link', href: '/roadshow/resources/',
            label: 'Presenter Resources', icon: 'handyman'},
        {type: 'link', href: '/am52/', label: 'am52', icon: 'video_library'},
        {type: 'link', href: '/fm52/', label: 'fm52', icon: 'video_library'},
        {type: 'link', href: '/bba/', label: 'Building Block Artists',
            icon: 'dashboard'},
        {type: 'link', href: '/vra/', label: 'Virtual Ready Artists',
            icon: 'dashboard'},
        {divider: true},
      */
      {type: 'link', href: '/showcases/', label: 'Upcoming Showcases',
        icon: 'event'},
      {type: 'link', href: '/about/', label: 'About', icon: 'info'},
      {type: 'link', href: '/contact/', label: 'Contact', icon: 'contact_page'},
      {type: 'link', href: '/gallery/', label: 'Gallery', icon: 'camera_alt'},
      {type: 'link', href: '/fm52/',
        label: 'Virtual Showcases', icon: 'video_library'},
      {type: 'link', href: 'https://onechoiceforchange.org',
        label: 'Food Drive', icon: 'food_bank', target: '_blank'},
      {label: 'Agent Tools', icon: 'expand_more', toggle: true, type: 'link',
        classes: `${constants.AGENT_TOGGLE}`},
      {divider: true, classes: `${constants.AGENT_ITEM} hidden`},
      {type: 'link', href: '/act/preview/',
        label: 'Sample Artist Page', icon: 'preview',
        classes: `${constants.AGENT_ITEM} hidden`},
      {type: 'link', href: '/act/preview/annotated',
        label: 'Annotated Artist Page', icon: 'preview',
        classes: `${constants.AGENT_ITEM} hidden`},
      {type: 'link', href: '/act/submit/link/', label: 'Artist Page Submission',
        icon: 'info', classes: `${constants.AGENT_ITEM} hidden`},
      /*
      {type: 'link', href: '/act/submit/sample-form/',
        label: 'Sample Artist Form',
        icon: 'list_alt', classes: `${constants.AGENT_ITEM} hidden`},
      */  
      {divider: true, classes: `${constants.AGENT_ITEM} hidden`},

    ];
  }

  static get adminItems() {
    return [
      {label: 'Admin', icon: 'expand_more', toggle: true, type: 'link',
        classes: `${constants.ADMIN_TOGGLE} hidden`},
      {divider: true, classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/agent/', label: 'Agent List',
        icon: 'group', classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/act/', label: 'Search for Acts',
        icon: 'group', classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/maintenance/?tbl=act', label: 'Act Maintenance',
        icon: 'edit_note', classes: `${constants.ADMIN_ITEM} hidden`},
      // {type: 'link', href: '/act/submit/agents/', label: 'Agent Promo Links',
      //  icon: 'link', classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/promo/validate/',
        label: 'Process Promo Submissions', icon: 'post_add',
        classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/act/submit/admin/',
        label: 'Create Promo Submission', icon: 'post_add',
        classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/listing/',
        label: 'Posters', icon: 'event',
        classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/logs/',
        label: 'View Logs', icon: 'view_list',
        classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/conferences/',
        label: 'Conferences', icon: 'browse_gallery',
        classe: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/reports/',
        label: 'Reports', icon: 'analytics',
        classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/maintenance/',
        label: 'Maintenance', icon: 'create',
        classes: `${constants.ADMIN_ITEM} hidden`},
      {type: 'link', href: '/admin/',
        label: 'Site Menu', icon: 'menu',
        classes: `${constants.ADMIN_ITEM} hidden`},
      {divider: true, classes: `${constants.ADMIN_ITEM} hidden`},
    ];
  }

  static get techItems() {
    return [
      // {divider: true, classes: constants.TECH_ITEM},
      {type: 'link', href: '/tech/', label: 'Tech',
        icon: 'headset', classes: `${constants.TECH_ITEM} hidden`},
    ];
  }

  static get epilogItems() {
    return [
      {type: 'link', href: '/privacy/', label: 'Privacy', icon: 'policy'},
      {type: 'link', href: '/', label: 'Home', icon: 'home'},
    ];
  }

  /*
  static get confItems() {
    return [
        {type: 'link', href: '/waa/', label: 'WAA 2023', icon: 'event'},
        {type: 'link', href: '/max/', label: 'MAX 2023', icon: 'event'},
        {type: 'link', href: '/apap/', label: 'APAP 2023', icon: 'event'},
      ];
  }
  */
  constructor() {
  }
}
