/**
 * FMPNavDrawer: lit-element implementation of MDCDrawer component
 *  customized as common navigation menu for site
 * @license MIT
 * Copyright 2023 Four Mortals Productions, Inc.
 * @version 24.1.0
 * Module history:
 *    1.0.0 - Initial build
 *   13.0.0 - @material 13.0, lit 2.0
 *   14.4.5 - Extend FMPElement
 *          - Modify for theme/5.1
 *   24.1.0 - Use Material Webv (m3) component
 * @since September 2020
 * @author Earl Rogers
 */

'use strict';

import {html, LitElement, nothing, unsafeCSS} from 'lit';
import {classMap} from 'lit/directives/class-map.js';
import style from './_index.scss';
/*
  Already loaded by build_page.php
  import '@material/web/iconbutton/icon-button.js';
*/
import {getListItem} from '@web/list/component.js';
import {strings as constants} from '../constants.js';
import {constants as loginConstants, events as loginEvents}
  from '@fourmortals/login/constants.js';
import {credentialsFromToken, tokenFromCookie}
  from '@fourmortals/login/util.js';
import {NavDrawerItems} from '../navitems.js';

export class FMPNavDrawer extends LitElement {
  static get changedEvent() {
    return 'navigation-drawer-changed';
  }
  /**
   * Set CSS for this component
   * @return {css}
   */
  static get styles() {
    return unsafeCSS(style);
  }
  /**
   * Define properties for this component
   * @return {Object}
   */
  static get properties() {
    return {
      opened: {type: Boolean},
      currentItem: {type: Object, attribute: false},
      userCredentials: {type: Object, attribute: false,
        hasChanged(newVal, oldVal) {
          return newVal?.role !== oldVal?.role;
        }},
    };
  }
  constructor() {
    super();
    this.element = undefined;
    this.currentItem = undefined;
    this.opened = false;
    this.closeIcon = undefined;

    /*
    this.signinHref = '/authenticate/';
    this.signinLabel = 'Sign in';
    this.signinIcon = 'login';
    */
    this.userCredentials_ = undefined;
    /* eslint-disable max-len */

    /*
    const allItems =[...NavDrawerItems.defaultItems,
      ...NavDrawerItems.adminItems,
      ...NavDrawerItems.techItems,
      ...NavDrawerItems.epilogItems];

    if (allItems.some((item) => item.href &&
      (
        item.href === location.pathname ||
        (item.href + '/') === location.pathname ||
        item.href.substring(0, item.href.length - 1) === location.pathname
      ),
    )
    ) {
      // menu contains the current url
    } else {
      // Add item for url not in the menu
      switch (location.pathname) {
        case '/video':
        case '/video/':
        case '/showcase':
        case '/showcase/': {
          const path = location.pathname.endsWith('/') ?
            location.pathname :
            `${location.pathname}/`;
          const s = location.pathname.replace(/\//g, '').trim();
          const lbl = s.charAt(0).toUpperCase() + s.slice(1);
          NavDrawerItems.defaultItems.splice(7, 0,
              {type: 'link', href: path, label: lbl, icon: 'music_video'});
          break;
        }
        default: {
          const path = location.pathname.endsWith('/') ?
            location.pathname :
            `${location.pathname}/`;
          const s = location.pathname.replace(/\//g, '').trim();
          switch (s) {
            case 'conference':
              break;
            case 'conferences': {
              const lbl = 'Conference Index';
              NavDrawerItems.defaultItems.push(
                  {type: 'link', href: path, label: lbl, icon: 'list'},
              );
              break;
            }
            case 'tables': {
              const lbl = 'Table Index';
              NavDrawerItems.defaultItems.push(
                  {type: 'link', href: path, label: lbl, icon: 'list'},
              );
              break;
            }
            case 'apap':
            case 'max':
            case 'waa':
              break;
            // case 'bba': {
            //  const lbl = 'Building Block Artists';
            //  NavDrawerItems.defaultItems.push(
            //       {type: 'link', href: path, label: lbl, icon: 'person_search'},
            //   );
            //   break;
            //  }
            //  case 'vra': {
            //   const lbl = 'Virtual Ready Artists';
            //   NavDrawerItems.defaultItems.push(
            //       {type: 'link', href: path, label: lbl, icon: 'person_search'},
            //   );
            //   break;
            //  }
            case 'fillyourseason': {
              const lbl = 'Fill Your Season';
              NavDrawerItems.defaultItems.push(
                  {type: 'link', href: path, label: lbl, icon: 'video_library'},
              );
              break;
            }
            case 'aip':
            case 'am52':
            case 'fm52':
            case 'gbam':
            case 'robinklinger': {
              NavDrawerItems.defaultItems.push(
                  {type: 'link', href: path, label: s, icon: 'video_library'},
              );
              break;
            }
            default: {
              const lbl = s.charAt(0).toUpperCase() + s.slice(1);
              NavDrawerItems.defaultItems.push(
                  {type: 'link', href: path, label: lbl, icon: 'web'},
              );
            }
          }
        }
      }
    }
    */
  }

  appendItems(items) {
    const oldItems = [...NavDrawerItems.defaultItems];
    NavDrawerItems.defaultItems = [];
    NavDrawerItems.defaultItems = [...oldItems, ...items];
  }

  prependItems(items) {
    const oldItems = [...NavDrawerItems.defaultItems];
    NavDrawerItems.defaultItems = [];
    setTimeout(() => {
      NavDrawerItems.defaultItems = [...items, ...oldItems];
    }, 500);
  }

  firstUpdated(changedProperties) {
    this.element = this.shadowRoot.querySelector('nav');
    document.addEventListener('MDCTopAppBar:nav', () => {
      this.opened = true;
    });

    /*
    this.addEventListener(this.constructor.changedEvent, (event) => {
      //
    });
    */

    this.closeIcon = this.element.querySelector('md-icon-button');
    this.closeIcon.addEventListener('click', (event) => {
      this.opened = !this.opened;
    });

    document.addEventListener(loginEvents.LOGIN_COMPLETE, (event) => {
      this.userCredentials = event.detail;
    });
    document.addEventListener(loginEvents.LOGOUT_COMPLETE, (event) => {
      this.userCredentials = event.detail;
    });
    this.setRoles();
    let el = this.element.querySelector(`.${constants.AGENT_TOGGLE}`);
    el.addEventListener('click', (event) => {
      this.toggleAgentTools(event);
    });
    el = this.element.querySelector(`.${constants.ADMIN_TOGGLE}`);
    el.addEventListener('click', (event) => {
      this.toggleAdmin(event);
    });
    /*
    el = this.element.querySelector('#signin-toggle');
    el.addEventListener('click', (event) => {
      if (event.currentTarget.label === 'Sign in') {
        this.signin();
      } else {
        this.signout();
      }
    });
    */

    super.firstUpdated(changedProperties);
  }

  get userCredentials() {
    if (!this.userCredentials_) {
      const oldVal = this.userCredentials_;
      const token = tokenFromCookie();
      if (token) {
        this.userCredentials_ = credentialsFromToken(token);
      } else {
        this.userCredentials_ = loginConstants.DEFAULT_CREDENTIALS;
      }
      this.requestUpdate('userCredentials', oldVal);
    }
    return this.userCredentials_;
  }

  set userCredentials(value) {
    const oldVal = this.userCredentials_;
    if (value?.rc === 0) {
      value = loginConstants.DEFAULT_CREDENTIALS;
    }
    this.userCredentials_ = value;
    this.setRoles();
    this.requestUpdate('userCredentials', oldVal);
  }

  setRoles() {
    if (this.userCredentials.username) {
      this.signinHref = '/signout/';
      this.signinLabel = 'Sign out';
      this.signinIcon = 'logout';
      if (this.userCredentials.role === 'admin') {
        let els = [].slice.call(
            this.element.querySelectorAll(`.${constants.ADMIN_TOGGLE}`));
        for (const el of els) {
          el.classList.remove(constants.HIDDEN);
        }
        els = [].slice.call(
            this.element.querySelectorAll(`.${constants.TECH_ITEM}`));
        for (const el of els) {
          el.classList.remove(constants.HIDDEN);
        }
      }
      if (this.userCredentials.role === 'tech') {
        const els = [].slice.call(
            this.element.querySelectorAll(`.${constants.TECH_ITEM}`));
        for (const el of els) {
          el.classList.remove(constants.HIDDEN);
        }
      }
    } else {
      this.signinHref = '/authenticate/';
      this.signinLabel = 'Sign in';
      this.signinIcon = 'login';
    }
  }

  /*

  async getScript(key) {
    const url = new URL(`${location.origin}/build_page_resources.php`);
    url.search = new URLSearchParams({key: key});
    const response = await fetch(url);
    const obj= await response.json();
    const script = document.createElement('script');
    script.onload = (event) => {
      return script;
    };
    script.type = 'module';
    script.src = obj.script;
    document.body.appendChild(script);
  }

  async signout() {
    // console.log('signout requested');
    let script = document.querySelector('script[src^="web.dialog"]');
    if (script) {
      script.open = true;
      return;
    }
    await this.getScript('m3-dialog');
    console.log('dialog loaded');
    script = document.querySelector('script[src^="fourmortals.signout"]');
    if (!script) {
      await this.getScript('signout');
      console.log('signout loaded');
    }
  }
 */
  /*
  getUserDetails() {
    if (this.userCredentials?.username) {
      return html`
      <div class="footer">
        ${this.userCredentials.fullname}<br />
        ${this.userCredentials.username}<br />
        ${this.userCredentials.role}
      </div>`;
    } else {
      return '';
    }
  }
  */

  toggleAdmin(event) {
    event.preventDefault();
    event.stopPropagation();
    const els = [...this.element.querySelectorAll('.admin')];
    const item = this.element.querySelector(`.${constants.ADMIN_TOGGLE}`);
    const icon = item.querySelector('md-icon');
    if (icon.textContent === 'expand_less') {
      icon.textContent = 'expand_more';
    } else {
      icon.textContent = 'expand_less';
    }
    for (const el of els) {
      el.classList.toggle('hidden');
    }
  }

  toggleAgentTools(event) {
    event.preventDefault();
    event.stopPropagation();
    const els = [...this.element.querySelectorAll(`.${constants.AGENT_ITEM}`)];
    const item = this.element.querySelector(`.${constants.AGENT_TOGGLE}`);
    const icon = item.querySelector('md-icon');
    if (icon.textContent === 'expand_less') {
      icon.textContent = 'expand_more';
    } else {
      icon.textContent = 'expand_less';
    }
    for (const el of els) {
      el.classList.toggle('hidden');
    }
  }

  getListItem(props) {
    if (props.href &&
      (
        props.href === location.pathname ||
        (props.href + '/') === location.pathname ||
        props.href.substring(0, props.href.length - 1) === location.pathname
      )) {
      props.disabled = true;
      props.type = 'text';
      delete props.href;
    }
    return getListItem(props);
  }

  getRenderClasses() {
    return classMap({
      'nav--opened': this.opened,
    });
  }

  getScrimClasses() {
    return classMap({
      'nav__scrim-visible': this.opened,
    });
  }

  handleKeyDown(event) {
    if (event.code === 'Escape') {
      this.opened = false;
    }
  }

  handleScrimClick() {
    this.opened = !this.opened;
  }

  render() {
    const ariaExpanded = this.opened ? 'true' : 'false';
    const ariaHidden = !this.opened ? 'true' : 'false';
    // Needed for closure conformance
    const {ariaLabel, ariaModal} = this;

    /* eslint-disable max-len, indent */
    return html`
    <div
        class="nav__scrim ${this.getScrimClasses()}"
        @click="${this.handleScrimClick}">
      </div>
    <nav class="nav ${this.getRenderClasses()}"
        aria-expanded=${ariaExpanded}
        aria-hidden=${ariaHidden}
        aria-label=${ariaLabel || nothing}
        aria-modal=${ariaModal || nothing}
        @keydown="${this.handleKeyDown}">
      <div class="nav__closebtn-container">
        <md-icon-button role="presentation">
          <md-icon aria-hidden="true">close</md-icon>
        </md-icon-button>
      </div>
      <div class="nav__slot-content">
        <md-list class="nav-list"> 
          ${NavDrawerItems.defaultItems.map((item, index) => html `
            ${this.getListItem(item)}
          `)}
          ${NavDrawerItems.adminItems.map((item, index) => html `
            ${this.getListItem(item)}
          `)}
          ${NavDrawerItems.techItems.map((item, index) => html `
            ${this.getListItem(item)}
          `)}
          ${this.getListItem(
            {
            type: 'link',
            href: this.signinHref,
            // id: 'signin-toggle',
            label: this.signinLabel,
            icon: this.signinIcon,
            },
          )}
          ${NavDrawerItems.epilogItems.map((item, index) => html `
            ${this.getListItem(item)}
          `)}
        </md-list>
      </div>
    </nav>
    <div class="mdc-drawer-scrim"></div>
    `;
    /* eslint-enable max-len */
  }

  updated(changedProperties) {
    if (changedProperties.has('opened')) {
        setTimeout(() => {
            this.dispatchEvent(new CustomEvent(this.constructor.changedEvent, {
                detail: {opened: this.opened},
                bubbles: true,
                composed: true,
            }));
        }, 250);
    }
  }
}
// Register the new element with the browser.
customElements.define('fmp-nav-drawer', FMPNavDrawer);
